<template>
  <div style="margin: 0;padding: 0">
    <div style="background-color: #0C3272;height: 80px;width: 100%">
<!--      <img :src="head" alt="" style="height: 80px;width: 100%"/>-->
    </div>
    <div style="position: absolute;top: 15px;left: 50px">
      <img :src="logo" style="height: 50px;width: 100%" alt="" />
    </div>
    <div style="position: absolute;top: 30px;right: 20px">
      <div style="display: flex">
        <div id="font_div">
          <p style="font-size: 12px;font-family: '苹方',serif;font-weight: 300;">{{ time }}</p>
        </div>
        <div id="username_div">
          欢迎您 {{ this.GET_DEPART }} {{ this.GET_USER }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {setNowEek, setNowTimes} from "@/utils/time";

export default {
  name: "header",
  data(){
    return{
      head: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/hear.png',
      logo: 'https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/logo.png',
      username: '',
      time: ''
    }
  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_DEPART']),
  },
  created() {
    this.username = this.GET_USER;
    this.timer = setInterval(() => {
      this.gettime()
    }, 1000)
  },
  methods:{
    gettime(){
      const week = setNowEek();
      const timenow = setNowTimes(new Date());
      this.time = week + timenow;
    }
  }

}
</script>

<style scoped>
#font_div{
  color: white;
  border-radius: 5px;
  background:rgba(255,255,255,0.5);
  height: 25px;
  line-height: 3px;
  width: 180px;
  text-align: center
}
#username_div{
  margin-left: 50px;
  font-family: '苹方',serif;
  color: white;
  font-weight: 500;
  font-size: 15px;
  margin-top: 2px
}
</style>
