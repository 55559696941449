<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">材料管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  材料信息-->
    <div style="width: 95%;margin:  auto;min-height: 80vh;background-color: #FFFFFF">
      <div style="padding-left: 3%;padding-top: 0.5%">
        <div v-if="GET_BDST === '1'">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区 </el-tag>
          <el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 15%" @change="changeMaterial()">
            <el-option
                v-for="item in bdsts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <div style="margin-top: 1%;display: flex">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 材料名称 </el-tag>
          <el-input v-model="material_name" placeholder="请输入材料名称" size="small" style="width: 15%" @input="changeMaterial()"/>
          <el-button style="margin-left: 5%" type="primary" size="small" @click="getPrienter">连接打印机</el-button>
        </div>
        <div>

        </div>
      </div>
    <!--  材料列表-->
      <div style="padding-left: 3%;padding-top: 0.5%">
        <el-table :data="materials" style="width: 100%">
          <el-table-column prop="bdst" label="所属工区" width="180" />
          <el-table-column prop="materialName" label="材料名称" width="280" />
          <el-table-column prop="materialModel" label="规格型号" width="280" />
          <el-table-column prop="materialNum" label="当前库存" width="280" />
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button type="primary" @click="material_warehouse(scope.row)"> 入库 </el-button>
              <el-button type="danger" @click="material_issue(scope.row)"> 出库 </el-button>
              <el-button type="warning" @click="material_qrcode(scope.row)"> 生成二维码 </el-button>
              <el-button type="success" @click="printerQrcode(scope.row)"> 打印二维码 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页-->
      <div v-if="first" style="float: right;margin-top: 20px;">
        <el-pagination v-model:currentPage="pagenum"
                       :small="true"
                       background layout="prev, pager, next" :total="totals">
        </el-pagination>
      </div>
      <div v-if="!first" style="float: right;margin-top: 20px;">
        <el-pagination v-model:currentPage="pagenum1"
                       :small="true"
                       background layout="prev, pager, next" :total="totals1">
        </el-pagination>
      </div>
    </div>
  <!--  入库弹窗-->
    <div>
      <el-dialog
          v-model="dialogWarehouse"
          title="材料入库"
          width="60%"
          :before-close="handleCloseWare">
        <div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">材&nbsp;料&nbsp;名&nbsp;称:</el-tag>
            <span>{{ ware_material_name }}</span>
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">规&nbsp;格&nbsp;型&nbsp;号:</el-tag>
            <span>{{ material_model }}</span>
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">供&nbsp;应&nbsp;单&nbsp;位:</el-tag>
            <el-select v-model="warehouse_source" class="m-2" placeholder="请选择供应单位" size="small">
              <el-option
                  v-for="item in warehouse_sources"
                  :key="item.sourceName"
                  :label="item.sourceName"
                  :value="item.sourceName"
              />
            </el-select>
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">生&nbsp;产&nbsp;厂&nbsp;家:</el-tag>
            <el-select v-model="warehouse_manu" class="m-2" placeholder="请选择生产厂家" size="small">
              <el-option
                  v-for="item in warehouse_manus"
                  :key="item.manufacturer"
                  :label="item.manufacturer"
                  :value="item.manufacturer"
              />
            </el-select>
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">入&nbsp;库&nbsp;时&nbsp;间:</el-tag>
            <el-input v-model="warehouse_time" placeholder="入库时间" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">合格证编号:</el-tag>
            <el-input v-model="warehouse_spba" placeholder="合格证编号" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">入&nbsp;库&nbsp;数&nbsp;量:</el-tag>
            <el-input v-model="warehouse_num" placeholder="入库数量" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">含&nbsp;税&nbsp;单&nbsp;价:</el-tag>
            <el-input v-model="warehouse_money" placeholder="含税单价" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">税&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;率:</el-tag>
            <el-input v-model="warehouse_cess" placeholder="税率" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">运&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;费:</el-tag>
            <el-input v-model="warehouse_freight" placeholder="运费" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">车&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌:</el-tag>
            <el-input v-model="warehouse_carnum" placeholder="车牌" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">存&nbsp;&nbsp;&nbsp;放&nbsp;&nbsp;&nbsp;地:</el-tag>
            <el-input v-model="warehouse_place" placeholder="存放地" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">入&nbsp;库&nbsp;备&nbsp;注:</el-tag>
            <el-input v-model="warehouse_remarks" placeholder="入库备注" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">材&nbsp;料&nbsp;照&nbsp;片:</el-tag>
            <el-upload
                ref="upload1"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="true"
                :data="dataup1"
                :on-change="imgchange1"
                :on-remove="handleRemove1"
                :multiple="false"
                :on-success="uploadMaterial"
            >
              <el-button type="primary" style="margin-left: 35%">请选择材料照片</el-button>
            </el-upload>
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">车&nbsp;辆&nbsp;照&nbsp;片:</el-tag>
            <el-upload
                ref="upload2"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="true"
                :data="dataup2"
                :on-change="imgchange2"
                :on-remove="handleRemove2"
                :multiple="false"
                :on-success="uploadCar"
            >
              <el-button type="primary" style="margin-left: 35%">请选择车辆照片</el-button>
            </el-upload>
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">运&nbsp;单&nbsp;照&nbsp;片:</el-tag>
            <el-upload
                ref="upload3"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="true"
                :data="dataup3"
                :on-change="imgchange3"
                :on-remove="handleRemove3"
                :multiple="false"
                :on-success="uploadFreight"
            >
              <el-button type="primary" style="margin-left: 35%">请选择运单照片</el-button>
            </el-upload>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="material_warehouse_yes()">入库</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  出库弹窗-->
    <div>
      <el-dialog
          v-model="dialogIssue"
          title="材料出库"
          width="60%"
          :before-close="handleCloseIssue">
        <div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">材&nbsp;料&nbsp;名&nbsp;称:</el-tag>
            <span>{{ ware_material_name }}</span>
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">规&nbsp;格&nbsp;型&nbsp;号:</el-tag>
            <span>{{ material_model }}</span>
          </div>

          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">出&nbsp;库&nbsp;时&nbsp;间:</el-tag>
            <el-input v-model="issue_time" placeholder="入库时间" clearable />
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">用&nbsp;料&nbsp;单&nbsp;位:</el-tag>
            <el-select v-model="issue_source" class="m-2" placeholder="请选择用料单位" size="small" @change="get_issue_manu">
              <el-option
                  v-for="item in issue_sources"
                  :key="item.sourceName"
                  :label="item.sourceName"
                  :value="item.sourceName"
              />
            </el-select>
          </div>
          <div class="ware_div">
            <el-tag style="margin-right: 2%">入&nbsp;库&nbsp;批&nbsp;次:</el-tag>
            <el-select v-model="issue_manu" class="m-2" placeholder="请选择入库批次" size="small">
              <el-option
                  v-for="item in issue_manus"
                  :key="item.wareManufacturer"
                  :label="'批次:' + item.batch + '---库存:' + item.num"
                  :value="item.wareSource + '-' +item.wareManufacturer + '-' + item.num + '-' + item.materialName + '-' + item.model + '-' + item.batch"
              />
            </el-select>
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">使&nbsp;用&nbsp;部&nbsp;位:</el-tag>
            <el-input v-model="issue_place" placeholder="使用部位" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">出&nbsp;库&nbsp;数&nbsp;量:</el-tag>
            <el-input v-model="issue_num" placeholder="出库数量" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">领&nbsp;用&nbsp;人&nbsp;&nbsp;&nbsp;:</el-tag>
            <el-input v-model="issue_user" placeholder="领用人" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">车&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌:</el-tag>
            <el-input v-model="issue_carnum" placeholder="车牌" clearable />
          </div>

          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">出&nbsp;库&nbsp;备&nbsp;注:</el-tag>
            <el-input v-model="issue_remarks" placeholder="出库备注" clearable />
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">材&nbsp;料&nbsp;照&nbsp;片:</el-tag>
            <el-upload
                ref="upload1"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="true"
                :data="dataup4"
                :on-change="imgchange4"
                :on-remove="handleRemove4"
                :multiple="false"
                :on-success="uploadMaterialIssue"
            >
              <el-button type="primary" style="margin-left: 35%">请选择材料照片</el-button>
            </el-upload>
          </div>
          <div style="display: flex"  class="ware_div">
            <el-tag style="margin-right: 2%">车&nbsp;辆&nbsp;照&nbsp;片:</el-tag>
            <el-upload
                ref="upload2"
                class="upload-demo"
                action="https://www.cr12cz.cn/czscsms/up/upload"
                :auto-upload="true"
                :data="dataup5"
                :on-change="imgchange5"
                :on-remove="handleRemove5"
                :multiple="false"
                :on-success="uploadCarIssue"
            >
              <el-button type="primary" style="margin-left: 35%">请选择车辆照片</el-button>
            </el-upload>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="material_issue_yes()">出库</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  二维码弹窗-->
    <div>
      <el-dialog
          v-model="dialogQrcode"
          title="材料二维码"
          width="20%"
          :before-close="handleCloseQrcode">
        <div>
          {{ qr_material_name }}
        </div>
        <el-divider />
        <div style="display: flex;justify-content: space-between">
          <!--材料信息-->
          <div>
            <!--规格型号-->
            <div style="margin-bottom: 5%">
              <p>规格型号</p>
              <p>{{ qr_material_model }}</p>
            </div>
            <!--计量单位-->
            <div style="margin-top: 5%">
              <p>计量单位</p>
              <p>{{ qr_material_unit }}</p>
            </div>
          </div>
        <!--  二维码-->
          <div style="margin-right: 20%">
            <div id="qrcode" ref="qrCodeUrl"></div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus';
import { setNowTimesMaterial, uuid } from '@/utils/time'
import QRCode from "qrcodejs2";
import {Decrypt, Encrypt} from '@/utils/secret';
import {
  DrawLableQrCode,
  getInstance,
  startJob,
  commitJob,
  InitDrawingBoard,
  DrawLableText, DrawLableLine, init, initSdk, selectPrinter, getAllPrinters, isJSON
} from "@/utils/jcPrinterSdk_api_third"
export default {
  name: "material_warehouse",
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  inject: ['reload'],
  data(){
    return{
      dataup1: {
        file: '',
        userid: ''
      },
      fileList1: [],
      dataup2: {
        file: '',
        userid: ''
      },
      fileList2: [],
      dataup3: {
        file: '',
        userid: ''
      },
      fileList3: [],
      dataup4: {
        file: '',
        userid: ''
      },
      fileList4: [],
      dataup5: {
        file: '',
        userid: ''
      },
      fileList5: [],
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '2标一工区/5标六工区',
        },
        {
          value: '22',
          label: '2标二工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
        {
          value: '57',
          label: '5标七工区',
        }
      ],
      material_name: '',
      materials: [],
      pagenum: 1,
      totals: 0,
      pagenum1: 1,
      totals1: 0,
      first: true,
      changeBdst: '',
      dialogWarehouse: false,
      dialogIssue: false,
      material_model: '',
      warehouse_carnum: '',
      warehouse_num: '',
      warehouse_money: '',
      warehouse_cess: '',
      warehouse_freight: '',
      warehouse_remarks: '',
      warehouse_place: '',
      warehouse_spba: '',
      warehouse_time: '',
      warehouse_source: '',
      warehouse_sources: [],
      ware_material_name: '',
      uuid: '',
      material_id: '',
      issue_remarks: '',
      issue_carnum: '',
      issue_num: '',
      issue_place: '',
      issue_source: '',
      issue_sources: [],
      issue_time: '',
      dialogQrcode: false,
      qr_material_name: '',
      qr_material_model: '',
      qr_material_unit: '',
      qr_material_id: '',
      issue_user: '',
      warehouse_manu: '',
      warehouse_manus: [],
      issue_manu: '',
      issue_manus: [],
      initSdkParam: {//初始化数据
        "fontDir": "",
      },

      InitDrawingBoardParam : {
        "width":70,
        "height":100,
        "rotate":0,
        "path":"ZT001.ttf",
        "verticalShift":0,
        "HorizontalShift":0
      },
      DrawTextParam: {
        "x": 20.0,//⽔平坐标
        "y": 45.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 80.0,//宽度， 单位mm
        "value": "",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 0,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawLineParam: {
        "x": 5.0,//⽔平坐标
        "y": 50.0,//垂直坐标
        "height": 1,//⾼度，单位mm
        "width": 97.0,//宽度， 单位mm
        "rotate": 90,//旋转⻆度
        "lineType": 1,//线条类型， 1:实线 2:虚线
        "dashwidth": [1,1]//虚线段⻓度
      },
      DrawTextParam1: {
        "x": 25.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "规格型号",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam2: {
        "x": 10.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 50.0,//宽度， 单位mm
        "value": "",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 5.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam3: {
        "x": 3.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "计量单位",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam4: {
        "x": -5.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 5.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawLableQrCodeParam : {
        "x": 10,
        "y": 55,
        "height": 35,
        "width": 35,
        "value": "",
        "rotate": 0,
        "codeType": 31,
      },
      imgSrc: ''
    }
  },
  created() {
    this.getMaterialInfo();
    getInstance();
    setTimeout(this.getPrienter,5000);
  },
  methods: {

    imgchange1(file) {
      this.fileList1.push(file);
    },
    handleRemove1(file) {
      for (let i = 0; i < this.fileList1.length; i++) {
        if (this.fileList1[i].raw.lastModified === file.raw.lastModified) {
          this.fileList1.splice(i, 1)
        }
      }
    },
    imgchange2(file) {
      this.fileList2.push(file);
    },
    handleRemove2(file) {
      for (let i = 0; i < this.fileList2.length; i++) {
        if (this.fileList2[i].raw.lastModified === file.raw.lastModified) {
          this.fileList2.splice(i, 1)
        }
      }
    },
    imgchange3(file) {
      this.fileList3.push(file);
    },
    handleRemove3(file) {
      for (let i = 0; i < this.fileList3.length; i++) {
        if (this.fileList3[i].raw.lastModified === file.raw.lastModified) {
          this.fileList3.splice(i, 1)
        }
      }
    },
    imgchange4(file) {
      this.fileList4.push(file);
    },
    handleRemove4(file) {
      for (let i = 0; i < this.fileList4.length; i++) {
        if (this.fileList4[i].raw.lastModified === file.raw.lastModified) {
          this.fileList4.splice(i, 1)
        }
      }
    },
    imgchange5(file) {
      this.fileList5.push(file);
    },
    handleRemove5(file) {
      for (let i = 0; i < this.fileList5.length; i++) {
        if (this.fileList5[i].raw.lastModified === file.raw.lastModified) {
          this.fileList5.splice(i, 1)
        }
      }
    },
    getMaterialInfo() {
      this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
        this.materials = response.obj.records;
        this.totals = response.obj.pages * 10;
      }, {
        bdst: this.GET_BDST,
        pageCount: 1
      })
    },
    changeMaterial() {
      if (this.bdst === '') {
        this.changeBdst = this.GET_BDST;
      } else {
        this.changeBdst = this.bdst;
      }
      this.first = false;
      if (this.material_name === '') {
        this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
          this.materials = response.obj.records;
          this.totals1 = response.obj.pages * 10;
        }, {
          bdst: this.changeBdst,
          pageCount: 1
        })
      } else {
        this.axios.post('/materialInfoemation/getMaterialByNamePc', (response) => {
          this.materials = response.obj.records;
          this.totals1 = response.obj.pages * 10;
        }, {
          bdst: this.changeBdst,
          materialName: this.material_name,
          pageCount: 1
        })
      }
    },
    handleCloseWare() {
      this.dialogWarehouse = false;
      this.material_id = '';
      this.warehouse_source = '';
      this.warehouse_time = '';
      this.warehouse_carnum = '';
      this.warehouse_num = '';
      this.warehouse_money = '';
      this.warehouse_cess = '';
      this.warehouse_freight = '';
      this.warehouse_place = '';
      this.warehouse_remarks = '';
      this.warehouse_spba = '';
    },
    material_warehouse(item) {
      this.uuid = uuid();
      let bdst;
      if (this.bdst === '' && this.GET_BDST === '1') {
        ElMessage.error('请先选择工区!')
      } else {
        if (this.GET_BDST === '1') {
          bdst = this.bdst;
        } else {
          bdst = this.GET_BDST
        }
        this.material_id = item.id;
        this.ware_material_name = item.materialName;
        this.material_model = item.materialModel;
        this.axios.post("/materialSource/getSourceByBdst", (response) => {
          this.warehouse_sources = response.obj;
        }, {
          bdst: bdst
        });
        this.axios.post("/materialManufacturer/getManu", (response) => {
          this.warehouse_manus = response.obj;
        }, {
          bdst: bdst
        });
        this.warehouse_time = setNowTimesMaterial(new Date());
        this.dialogWarehouse = true;

      }
    },
    // 入库材料照片
    uploadMaterial(response) {
      this.axios.post("/materialPhoto/saveMaterialPhoto", (response) => {

      }, {
        warehouse_id: this.uuid,
        flag: 0,
        photo_type: '材料照片',
        photo_url: response.obj
      })
    },
    // 入库车辆照片
    uploadCar(response) {
      this.axios.post("/materialPhoto/saveMaterialPhoto", (response) => {

      }, {
        warehouse_id: this.uuid,
        flag: 0,
        photo_type: '车辆照片',
        photo_url: response.obj
      })
    },
    // 出库材料照片
    uploadMaterialIssue(response) {
      this.axios.post("/materialPhoto/saveMaterialPhoto", (response) => {

      }, {
        warehouse_id: this.uuid,
        flag: 1,
        photo_type: '材料照片',
        photo_url: response.obj
      })
    },
    // 出库车辆照片
    uploadCarIssue(response) {
      this.axios.post("/materialPhoto/saveMaterialPhoto", (response) => {

      }, {
        warehouse_id: this.uuid,
        flag: 1,
        photo_type: '车辆照片',
        photo_url: response.obj
      })
    },
    // 入库运单照片
    uploadFreight(response) {
      this.axios.post("/materialPhoto/saveMaterialPhoto", (response) => {
        console.log(response)
      }, {
        warehouse_id: this.uuid,
        flag: 0,
        photo_type: '运单照片',
        photo_url: response.obj
      })
    },
    material_warehouse_yes() {
      this.axios.post('/materialWarehouse/materialWarehouse', (response) => {
        if (response.obj) {
          ElMessage({
            message: '入库成功!',
            type: 'success',
          });
          this.reload();
          this.dialogWarehouse = false;
          this.material_id = '';
          this.warehouse_source = '';
          this.warehouse_time = '';
          this.warehouse_carnum = '';
          this.warehouse_num = '';
          this.warehouse_money = '';
          this.warehouse_cess = '';
          this.warehouse_freight = '';
          this.warehouse_place = '';
          this.warehouse_remarks = '';
          this.warehouse_spba = '';
          this.warehouse_manu = '';
        }
      }, {
        id: this.uuid,
        material_id: this.material_id,
        material_source: this.warehouse_source,
        warehouse_time: this.warehouse_time,
        warehouse_carnum: this.warehouse_carnum,
        material_num: this.warehouse_num,
        material_money: this.warehouse_money,
        cess: this.warehouse_cess,
        material_freight: this.warehouse_freight,
        warehouse_place: this.warehouse_place,
        material_remarks: this.warehouse_remarks,
        sbpa_code: this.warehouse_spba,
        up_user: this.GET_USER,
        up_bdst: this.GET_BDST,
        manu: this.warehouse_manu,
        material_name: this.ware_material_name,
        model: this.material_model
      })
    },
    material_issue(item) {

      this.uuid = uuid();
      let bdst;
      if (this.bdst === '' && this.GET_BDST === '1') {
        ElMessage.error('请先选择工区!')
      } else {
        if (this.GET_BDST === '1') {
          bdst = this.bdst;
        } else {
          bdst = this.GET_BDST
        }
        this.material_id = item.id;
        this.ware_material_name = item.materialName;
        this.material_model = item.materialModel;
        this.axios.post("/materialSource/getIssueSourceByBdst", (response) => {
          this.issue_sources = response.obj;
        }, {
          bdst: bdst
        })
        this.issue_time = setNowTimesMaterial(new Date());
        this.dialogIssue = true;
      }
    },
    handleCloseIssue(){
      this.material_id = '';
      this.issue_source = '';
      this.issue_place = '';
      this.issue_time = '';
      this.issue_carnum = '';
      this.issue_num = '';
      this.dialogIssue = false;
    },
    material_issue_yes(){
			console.log(this.issue_manu)
      const  issue_source = this.issue_manu.split("-")[0];
      const  issue_manu = this.issue_manu.split("-")[1];
      const  issue_num_kc = this.issue_manu.split("-")[2];
      const material_name = this.issue_manu.split("-")[3];
      const material_model = this.issue_manu.split("-")[4];
	    const batch = this.issue_manu.split("-")[5];
      if (Number(this.issue_num) > Number(issue_num_kc)){
        ElMessage.error("出库数量大于库存数量，请检查!");
      }else{
        this.axios.post('/materialIssue/materialIssue', (response) => {
          if (response.obj) {
            ElMessage({
              message: '出库成功!',
              type: 'success',
            });
            this.reload();
            this.dialogIssue = false;
            this.material_id = '';
            this.issue_source = '';
            this.issue_place = '';
            this.issue_time = '';
            this.issue_carnum = '';
            this.issue_num = '';
            this.issue_manu = '';
          }
        },{
          id: this.uuid,
          material_id: this.material_id,
          issue_company: this.issue_source,
          issue_position:this.issue_place,
          issue_time:this.issue_time,
          issue_car:this.issue_carnum,
          issue_num:this.issue_num,
          issue_remarks: this.issue_remarks,
          up_user: this.GET_USER,
          up_bdst: this.GET_BDST,
          issue_user: this.issue_user,
          material_source: issue_source,
          material_name: material_name,
          manu: issue_manu,
          model: material_model,
	        batch: batch
        })
      }

    },
    handleCloseQrcode(){
      this.dialogQrcode = false;
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
    },
    material_qrcode(item){
      this.qr_material_name = item.materialName;
      this.qr_material_model = item.materialModel;
      this.qr_material_unit = item.materialUnit;
      this.qr_material_id = item.id;
      setTimeout(() => {
        this.creatQrCode()
      }, 100)
      this.dialogQrcode = true;
    },
    get_issue_manu(){
      this.axios.post('/materialWareManu/getManuBySource', (response) => {
        this.issue_manus = response.obj;
      },{
        bdst: this.bdst,
        material_name: this.ware_material_name,
        model: this.material_model
      })
    },
    creatQrCode() {
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: Encrypt(this.qr_material_id), // 需要转换为二维码的内容
        //text: 'http://182.92.228.212/czsc/#/mobilsel?qrcodeid=' + this.uuid, // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    // DrawLableQrCode(){
    //   let that = this;
    //   this.DrawLableQrCodeParam.value = '9B266C7BFD571AB7289A22FDA0915497';
    //   this.DrawTextParam2.value = '';
    //   this.DrawTextParam4.value = '';
    //   DrawLableText(this.DrawTextParam, function (){
    //     DrawLableLine(that.DrawLineParam, function (){
    //       DrawLableText(that.DrawTextParam1, function (){
    //         DrawLableText(that.DrawTextParam2, function (){
    //           DrawLableText(that.DrawTextParam3, function (){
    //             DrawLableText(that.DrawTextParam4, function (){
    //               DrawLableQrCode(that.DrawLableQrCodeParam, function (){
    //
    //               })
    //             })
    //           })
    //         })
    //       })
    //     })
    //   })
    // },
    // startJob(){
    //   startJob(3,1,1,1, function (data){
    //     console.log(data)
    //   })
    // },
    // commitJob(){
    //   let jsonObj = {"printerImageProcessingInfo": {"printQuantity":1}};
    //   commitJob(null, JSON.stringify(jsonObj), function (data) {
    //     console.log(data)
    //   })
    // },
    printerQrcode(item){
      this.qr_material_name = item.materialName;
      this.qr_material_model = item.materialModel;
      this.qr_material_unit = item.materialUnit;
      this.qr_material_id = item.id;
      const that = this;
      InitDrawingBoard(this.InitDrawingBoardParam, function (){
        that.DrawLableQrCodeParam.value = Encrypt(that.qr_material_id);
        that.DrawTextParam.value = that.qr_material_name;
        that.DrawTextParam2.value = that.qr_material_model;
        that.DrawTextParam4.value = that.qr_material_unit;
        DrawLableText(that.DrawTextParam, function (){
          DrawLableLine(that.DrawLineParam, function (){
            DrawLableText(that.DrawTextParam1, function (){
              DrawLableText(that.DrawTextParam2, function (){
                DrawLableText(that.DrawTextParam3, function (){
                  DrawLableText(that.DrawTextParam4, function (){
                    DrawLableQrCode(that.DrawLableQrCodeParam, function (){
                      startJob(3,1,1,1, function (data){
                        let jsonObj = {"printerImageProcessingInfo": {"printQuantity":1}};
                        commitJob(null, JSON.stringify(jsonObj), function (data) {
                          console.log(data)
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      });
    },
    // generateImagePreviewImage(){
    //   const that = this;
    //   generateImagePreviewImage(8, function (data){
    //     const arrParse = JSON.parse(JSON.stringify(data));
    //     //var Epc = document.getElementById('searchRfidBox');
    //     const base64Data = arrParse.resultAck.info;
    //     const obj = JSON.parse(base64Data);
    //     const data1 = obj.ImageData;
    //     that.imgSrc = "data:image/jpeg;base64," + data1;
    //   })
    // }
    getPrienter(){
      initSdk(this.initSdkParam, function (data){
        console.log(JSON.parse(JSON.stringify(data)).resultAck.errorCode)
        if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0){
          getAllPrinters(function (data) {
            var arrParse = JSON.parse(JSON.stringify(data));
            console.log('arrParse===========' + data)
            if (!arrParse.resultAck.result ) {
              return;
            } else if (JSON.parse(JSON.stringify(data)).resultAck.errorCode === 0) {
              let allPrinters;
              allPrinters = JSON.parse(arrParse.resultAck.info)
              let allPrintersName = Object.keys(allPrinters);
              let allPrintersValue = Object.values(allPrinters);

              selectPrinter(allPrintersName[0],parseInt(allPrintersValue[0]),(data=>{
                ElMessage({
                  message: '打印机连接成功!',
                  type: 'success',
                })
              }))
            }
          })
        }
      });

    }


  },
  watch: {
    pagenum(){
      this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
        this.materials = response.obj.records;
        this.totals = response.obj.pages * 10;
      },{
        bdst: this.GET_BDST,
        pageCount: this.pagenum
      })
    },
    pagenum1(){
      this.axios.post('/materialInfoemation/getMaterialByNamePc', (response) => {
        this.materials = response.obj.records;
        this.totals1 = response.obj.pages * 10;
      },{
        bdst: this.changeBdst,
        materialName: this.material_name,
        pageCount: this.pagenum1
      })

    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
.ware_div{
  margin-bottom: 2%;
}
</style>
