//websocket   精臣打印机 SDK
var g_websocket;

/* //响应数据 */
var ackJsonData;

/** websocket（打印服务）连接状态*/
var websocketConnectStatus = false;
/**SDK初始化状态 */
var initSdkStatus =false;
/**设备状态 */
var deviceStatus =false;
/**消息列表 */
var MessageList = {};

/**通过websocket发送消息 */
function sendMsg(msg, callback) {
  console.log('sendMsg', msg.apiName);
  MessageList[msg.apiName] = callback;

  var data = JSON.stringify(msg);
  var tryTimes = 10;

  for (var i = 0; i < tryTimes; i++) {
    if (g_websocket.readyState === 1) {
      g_websocket.send(data);
      return;
    }
  }
}

//初始化打印服务
//1.1 获取接口实例JCAPI.getInstance（单例模式）
export function getInstance() {
  if ('WebSocket' in window) {
    console.log('初始化打印服务')
    g_websocket = websocketLifePeriod();
  } else {
    console.log('unsupported websocket');
    //return false;
    g_websocket = null;
    return false;
  }

  //保持在线
  setInterval(function () {
    if (g_websocket.readyState === 2 || g_websocket.readyState === 3) {
      getInstance();
    }
  }, 3000);

  return true;
}

//1.1.1 打开链接及回调
function websocketLifePeriod() {

  var websocket;
  websocket = new WebSocket('ws://127.0.0.1:37989');
  //websocket.binaryType = 'arraybuffer';
  websocket.onerror = function (evt) {//since there is an error, sockets will close so...
    sok.onclose = function (e) {
      console.log("WebSocket Error: ", e);
    }
  }

  websocket.proxy_read_timeout = 10000;
  websocket.binaryType = "arraybuffer";

  websocket.onopen = connectCallback;

  websocket.onclose = closeCallback;

  websocket.onmessage = readCallback;

  websocket.onerror = errorCallback;

  console.log(websocket);

  return websocket;
}

//1.1.2 websocket连接回调
function connectCallback(e) {
  ackJsonData = '';
  console.log('success');
  websocketConnectStatus=true;
  // init();
}

//1.1.3 关闭连接回调
function closeCallback(e) {
  websocketConnectStatus=false;
  console.log(
      'websocket closed: ' + e.code + ' ' + e.reason + ' ' + e.wasClean
  );
  // globalwebsocket = websocket;
  g_websocket.close();
  //websocketLifePeriod();
  if (e.code == 1005) {
    // globalwebsocket = websocket;
  }
  console.log('closed');
  ackJsonData = '';
}


//1.1.4 读回调
function readCallback(e) {
  var callBackInfo = e.data;
  console.log('readCallback', callBackInfo);
  ackJsonData = callBackInfo;

  var callbackName;

  if (isJSON(ackJsonData)) {
    var arrParse = JSON.parse(ackJsonData);

    //接口回调
    if (!!MessageList[arrParse.apiName]) {
      MessageList[arrParse.apiName](arrParse);
    }

    //回调分发
    if(arrParse.apiName=='printStatus'){
      if(arrParse['resultAck']['online']=='online'){
        deviceStatus=true;
      }else{
        deviceStatus=false;
      }
    }else{
      if (arrParse['resultAck']['callback'] != undefined) {
        callbackName = arrParse['resultAck']['callback']['name'];

        if (callbackName == 'onConnectSuccess') {
          var printerName = arrParse['resultAck']['callback']['printerName'];
          onConnectSuccess(printerName);
        } else if (callbackName == 'onDisConnect') {
          var printerName = arrParse['resultAck']['callback']['printerName'];
          onDisConnect(printerName);
        } else if (callbackName == 'onCoverStatusChange') {
          var coverStatus = arrParse['resultAck']['callback']['coverStatus'];
          onCoverStatusChange(coverStatus);
        } else if (callbackName == 'onElectricityChange') {
          var powerLever = arrParse['resultAck']['callback']['powerLever'];
          onElectricityChange(powerLever);
        } else if (callbackName == 'onPaperStatusChange') {
          var paperStatus = arrParse['resultAck']['callback']['paperStatus'];
          onPaperStatusChange(paperStatus);
        } else if (callbackName == 'onPrintPageCompleted') {
          onPrintPageCompleted();
        } else if (callbackName == 'onPrintProgress') {
          onPrintProgress();
        } else if (callbackName == 'onAbnormalResponse') {
          onAbnormalResponse();
        }
        else {
          console.log('unknow callback api!');
        }
      }
    }

    ackJsonData = '';
  }
}
//1.1.5 错误回调
function errorCallback(e) {
  //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
  console.log(e.data);
}

//封装初始化SDK/获取打印机列表/选择打印机
export function init(initSdkParam) {
  initSdk(initSdkParam, function (data) {
    var arrParse = JSON.parse(JSON.stringify(data));
    if (arrParse.resultAck.result != 0) {
      return;
    }

    getAllPrinters(function (data) {
      var arrParse = JSON.parse(JSON.stringify(data));

      if (!arrParse.resultAck.result ) {
        return;
      } else if (isJSON(arrParse.resultAck.info)) {
        allPrinters = JSON.parse(arrParse.resultAck.info)
        let allPrintersName = Object.keys(allPrinters);
        let allPrintersValue = Object.values(allPrinters);

        selectPrinter(allPrintersName[0],parseInt(allPrintersValue[0]),(data=>{
          initSdkStatus=true;
        }))
      }
    })

  })
}

//1.2 断开websocket连接
function unInitPrintInstance() {
  g_websocket.close();
}

//2.1 打印机连接成功回调onConnectSuccess
function onConnectSuccess(printerName) {
  console.log('打印机连接成功!');
  initSdkStatus=true;
  deviceStatus=true;
}

//2.2 打印机断开回调onDisConnect
function onDisConnect(printerName) {
  console.log('打印机断开！');
  initSdkStatus=false;
}

//2.3 打印机上盖变化回调onCoverStatusChange
function onCoverStatusChange(coverStatus) {
  console.log('打印机盒盖有变化！');
}

//2.4 打印机电量变化回调onElectricityChange()
function onElectricityChange(powerLever) {
  console.log('打印机电量有变化！');
}

//2.5 打印机纸张状态变化回调onPaperStatusChange
function onPaperStatusChange(paperStatus) {
  console.log('打印机纸张状态有变化！');
}

//3.1 页打印成功回调onPrintPageCompleted
function onPrintPageCompleted() {
  console.log('页打印状态有变化！');
}

//3.2 打印进度回调onPrintProgress
function onPrintProgress() {
  console.log('打印进度有变化！');
}

//3.3 打印异常回调onAbnormalResponse
function onAbnormalResponse() {
  console.log('打印异常！');
}

//获取所有当前PC上连接的精臣打印机
//4.1 获取打印机列表getAllPrinters()
export function getAllPrinters(callbackFunction) {
  //刷新设备时，关闭设备
  //closePrinter();
  var jsonObj = { apiName: 'getAllPrinters' };
  var allDevice = {};

  sendMsg(jsonObj, callbackFunction);
}

//5. 图片打印
/*base64Data--图片base64数据
nPrintCount--打印数量
bDenoise--降噪*/
function picturePrint(base64Data, nPrintCount, bDenoise, callbackFunction) {
  var jsonObj = {
    apiName: 'picturePrint',
    parameter: {
      data: base64Data,
      nPrintCount: nPrintCount,
      bDenoise: bDenoise
    }
  };

  sendMsg(jsonObj, callbackFunction);
}

//6.选择并打开需要使用的打印机名称，及端口号
export function selectPrinter(printerName, port, callbackFunction) {
  var jsonObj = {
    apiName: 'selectPrinter',
    parameter: { printerName: printerName, port: port }
  };
  sendMsg(jsonObj, callbackFunction);
}

//7.停止打印
function stopPrint(callbackFunction) {
  var jsonObj = { apiName: 'stopPrint' };
  sendMsg(jsonObj, callbackFunction);
}

//8.关闭打印端口
function closePrinter(callbackFunction) {
  var jsonObj = { apiName: 'closePrinter' };
  sendMsg(jsonObj, callbackFunction);
}

//9.设置打印浓度
//nDensity--范围为getDensityScopeApi查询范围
function setPrintDensity(nDensity, callbackFunction) {
  var jsonObj = {
    apiName: 'setPrintDensity',
    parameter: { nDensity: nDensity }
  };
  sendMsg(jsonObj, callbackFunction);
}

//10.设置打印速度
//nSpeed--范围为getSpeedScopeApi查询的范围
function setPrintSpeed(nSpeed, callbackFunction) {
  var jsonObj = { apiName: 'setPrintSpeed', parameter: { nSpeed: nSpeed } };
  sendMsg(jsonObj, callbackFunction);
}

//11.设置打印标贴类型
//nType--间隙：01，黑标：02，连续纸：03，定位孔：04，透明纸：05，随机打印：06
function setPrintLabelType(nType, callbackFunction) {
  var jsonObj = { apiName: 'setPrintLabelType', parameter: { nType: nType } };
  sendMsg(jsonObj, callbackFunction);
}


//12.设置关机时间
//nType--1：15分钟，2:30分钟，3:60分钟，4：never
function setPrinterAutoShutDownTime(nType, callbackFunction) {
  var jsonObj = {
    apiName: 'setPrinterAutoShutDownTime',
    parameter: { nType: nType }
  };
  sendMsg(jsonObj, callbackFunction);
}

//13.复位打印机
function setPrinterReset(callbackFunction) {
  var jsonObj = { apiName: 'setPrinterReset' };
  sendMsg(jsonObj, callbackFunction);
}

//14. 纸张标定
function setPrintPaperPos(nType, callbackFunction) {
  var jsonObj = {
    apiName: 'setPrintPaper',
    parameter: { nType: nType }
  };
  sendMsg(jsonObj, callbackFunction);
}

//15.获取打印速度
function getPrintSpeed(callbackFunction) {
  var jsonObj = { apiName: 'getPrintSpeed' };
  sendMsg(jsonObj, callbackFunction);
}

//15.获取打印机电量
function getPower(callbackFunction) {
  var jsonObj = { apiName: 'getPower' };
  sendMsg(jsonObj, callbackFunction);
}

//16.获取标贴类型
function getPrintLabelType(callbackFunction) {
  var jsonObj = { apiName: 'getPrintLabelType' };

  sendMsg(jsonObj, callbackFunction);
}

//17.获取打印浓度
function getPrintDensity(callbackFunction) {
  var jsonObj = { apiName: 'getPrintDensity' };
  sendMsg(jsonObj, callbackFunction);
}

//18.获取打印机语言
function getPrinterLanguageType(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterLanguageType' };
  sendMsg(jsonObj, callbackFunction);
}

//19.查询关机时间
function getPrinterAutoShutDownTime(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterAutoShutDownTime' };
  sendMsg(jsonObj, callbackFunction);
}

//20.获取打印机序列号
function getPrinterSn(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterSn' };
  sendMsg(jsonObj, callbackFunction);
}

//21.获取硬件版本
function getPrinterHardwareVersion(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterHardwareVersion' };
  var responseData = {};
  sendMsg(jsonObj, callbackFunction);
}

//22.获取软件版本
function getPrinterSoftwareVersion(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterSoftwareVersion' };
  sendMsg(jsonObj, callbackFunction);
}

//23. 获取打印机语言
function setPrinterLanguageType(nType, callbackFunction) {
  var jsonObj = {
    apiName: 'setPrinterLanguageType',
    parameter: { nType: nType }
  };
  sendMsg(jsonObj, callbackFunction);
}

//24.获取打速度印范围
function getSpeedScope(callbackFunction) {
  var jsonObj = { apiName: 'getSpeedScope' };
  sendMsg(jsonObj, callbackFunction);
  return getResult(5, 'getSpeedScope', 'set printer language timeout!');
}

//25. 获取已开启的打印机
function getConnectPrinter(callbackFunction) {
  var jsonObj = { apiName: 'getConnectPrinter' };
  sendMsg(jsonObj, callbackFunction);
  return getResult(5, 'getConnectPrinter', 'get connect printer timeout!');
}

//26.获取打印机型号
function getPrinterType(callbackFunction) {
  var jsonObj = { apiName: 'getPrinterType' };
  sendMsg(jsonObj, callbackFunction);
}

//27.获取浓度范围
function getDensityScope(callbackFunction) {
  var jsonObj = { apiName: 'getDensityScope' };
  sendMsg(jsonObj, callbackFunction);
}

//28. 获取打印模式 1-热敏 2-热转印
function getPrinterMode(callbackFunction) {
  var jsonObj = { apiName: 'getPrintMode' };
  sendMsg(jsonObj, callbackFunction);
}

//29.获取打印机mac地址
function getMacAddress(callbackFunction) {
  var jsonObj = { apiName: 'getMacAddress' };
  sendMsg(jsonObj, callbackFunction);
}

//30.设置打印模式
//nType-1热敏，2碳带
function setPrintMode(nType, callbackFunction) {
  var jsonObj = { apiName: 'setPrintMode', parameter: { nType: nType } };
  sendMsg(jsonObj, callbackFunction);
}

/**
 * 31.开始打印任务
 * @param {number} printDensity 打印浓度
 * @param {number} printLabelType 纸张类型
 * @param {number} printMaterial 材质
 * @param {number} printMode 打印模式
 * @param {number} count 总打印张数
 * @param {*} callbackFunction 回调函数
 */
export function startJob(printDensity, printLabelType, printMode, count, callbackFunction) {
  var jsonObj = {
    apiName: 'startJob',
    parameter: {
      printDensity: printDensity,
      printLabelType: printLabelType,
      printMode: printMode,
      count: count
    }
  };
  sendMsg(jsonObj, callbackFunction);
}

//32.提交打印任务commitJob
export function commitJob(printData, printerImageProcessingInfo, callbackFunction) {
  var printDataJson = eval('(' + printData + ')');
  var printerImageProcessingInfoJson = eval('(' + printerImageProcessingInfo + ')');
  var jsonObj = {
    apiName: 'commitJob',
    parameter: {
      printData: printDataJson,
      printerImageProcessingInfo: printerImageProcessingInfoJson['printerImageProcessingInfo'],
    }
  };
  sendMsg(jsonObj, callbackFunction);
}

//33.结束打印任务endJob
function endJob(callbackFunction) {
  var jsonObj = { apiName: 'endJob' };
  sendMsg(jsonObj, callbackFunction);
}

//33.取消打印任务cancleJob
function cancleJob(callbackFunction) {
  var jsonObj = { apiName: 'stopPrint' };
  sendMsg(jsonObj, callbackFunction);
}

//34.1 创建画板
export function InitDrawingBoard(json, callbackFunction) {
  var jsonObj = {
    apiName: 'InitDrawingBoard',
    parameter: json
  };
  console.log("宽度:" + json.width)
  console.log("高度:" + json.height)
  console.log("旋转角度:" + json.rotate)
  sendMsg(jsonObj, callbackFunction);
}

//34.2 绘制文本
export function DrawLableText(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableText',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.3 绘制一维码
function DrawLableBarCode(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableBarCode',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.4 绘制二维码
export function DrawLableQrCode(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableQrCode',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.5 绘制线条
export function DrawLableLine(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableLine',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.6 绘制图形
function DrawLableGraph(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableGraph',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.7 绘制图像
function DrawLableImage(json, callbackFunction) {
  var jsonObj = {
    apiName: 'DrawLableImage',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}

//34.8 生成预览图
export function generateImagePreviewImage(displayScale, callbackFunction) {
  var jsonObj = {
    apiName: 'generateImagePreviewImage',
    displayScale: displayScale
  };

  sendMsg(jsonObj, callbackFunction);
}

//35. 初始化SDK
export function initSdk(json, callbackFunction) {
  var jsonObj = {
    apiName: 'initSdk',
    parameter: json
  };

  sendMsg(jsonObj, callbackFunction);
}


//36. 获取调用结果
function getResult(tryTime, apiName, errInfo) {
  tryTimes = tryTime;

  let result = {};
  while (tryTimes--) {
    if (!isJSON(ackJsonData)) continue;

    var arrParse = JSON.parse(ackJsonData);
    if (arrParse['apiName'] === apiName) {
      result = arrParse['resultAck'];
      break;
    }
  }

  if (tryTimes <= 0) {
    result['result'] = false;
    result['errorCode'] = 0x12;
    result['info'] = errInfo;
  }
  return result;
}

//37. 判断字符串是否为JSON
export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }

    } catch (e) {
      //console.log('error：'+str+'!!!'+e);
      return false;
    }

  }

  console.log('It is not a string!');
}
